import { render, staticRenderFns } from "./member-list-man.vue?vue&type=template&id=2fac03d9&scoped=true&"
import script from "./member-list-man.vue?vue&type=script&lang=js&"
export * from "./member-list-man.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fac03d9",
  null
  
)

export default component.exports